import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientsState, ExtendedClient } from './clients.model';
import { adapter, clientsFeatureKey } from './clients.reducer';
import { ProcedureRecord, VisitProcedureRecord } from '../../shared/models';

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectClients =
  createFeatureSelector<ClientsState>(clientsFeatureKey);
export const selectAllClients = createSelector(selectClients, selectAll);
export const selectClientEntities = createSelector(
  selectClients,
  selectEntities
);

export const getSelectedClient = createSelector(
  selectClients,
  selectClientEntities,
  (state, entities) =>
    state.selectedClientId ? entities[state.selectedClientId] : undefined
);

export const getActiveClient = createSelector(
  selectClients,
  selectClientEntities,
  (state: ClientsState, entities) => entities[state.selectedClientId]
);

export const getClientProcedures = createSelector(
  getSelectedClient,
  (client: ExtendedClient | undefined): ProcedureRecord[] =>
    client?.procedures || []
);

export const getClientVisitProcedures = createSelector(
  getSelectedClient,
  (client: ExtendedClient | undefined): VisitProcedureRecord[] =>
    client?.procedures?.map((procedure) => ({
      ...procedure,
      ...procedure.visit,
      visitId: procedure.visit.id,
      visitDate: procedure.visit.date
    })) || []
);
