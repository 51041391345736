import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendService } from '../backend/backend.service';
import {
  ProcedureRecord,
  Client,
  ProcedureVisitRecord,
  CreateProcedureDTO
} from '../../shared/models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProceduresService {
  private readonly url: string;

  private get clientsSegment(): string {
    return `${this.backendService.nestApi}/${environment.endpoints.clients}`;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly backendService: BackendService
  ) {
    this.url = `${this.backendService.nestApi}/procedures`;
  }

  public add(record: ProcedureRecord): Observable<CreateProcedureDTO> {
    return this.http.post<CreateProcedureDTO>(this.url, record, {
      headers: this.backendService.headers
    });
  }

  public update(procedure: ProcedureRecord): Observable<CreateProcedureDTO> {
    return this.http.put<CreateProcedureDTO>(
      `${this.url}/${procedure.id}`,
      procedure,
      {
        headers: this.backendService.headers
      }
    );
  }

  public delete(procedureId: number): Observable<void> {
    return this.http.delete<void>(`${this.url}/${procedureId}`, {
      headers: this.backendService.headers
    });
  }

  public get(clientId: Client['id']): Observable<ProcedureRecord[]> {
    return this.http.get<ProcedureRecord[]>(
      `${this.backendService.nestApi}/clients/${clientId}/procedures`,
      {
        headers: this.backendService.headers
      }
    );
  }
  public getProcedureById(id: number): Observable<ProcedureRecord> {
    return this.http.get<ProcedureRecord>(
      `${this.backendService.nestApi}/procedures/${id}`,
      {
        headers: this.backendService.headers
      }
    );
  }

  public getClientProcedures(
    clientId: number
  ): Observable<ProcedureVisitRecord[]> {
    return this.http.get<ProcedureVisitRecord[]>(
      `${this.clientsSegment}/${clientId}/procedures`,
      { headers: this.backendService.headers }
    );
  }
}
