import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as VisitActions from './visit.actions';
import { VisitsState } from '../../store/visits/visits.model';
import { Visit } from '../../shared/models';

export const visitsFeatureKey = 'visits';

export const adapter: EntityAdapter<Visit> = createEntityAdapter<Visit>({
  sortComparer: (a: Visit, b: Visit) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB.getTime() - dateA.getTime(); // For descending order
  }
});

export const initialState: VisitsState = adapter.getInitialState({
  selectedVisitId: null,
  paginationInfo: {
    page: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPreviousPage: false
  },
  ids: [],
  error: null
});

export const visitReducer = createReducer(
  initialState,

  // Handle adding, updating, and deleting visits
  on(VisitActions.addVisitSuccess, (state, action) =>
    adapter.addOne(action.visit, state)
  ),
  on(VisitActions.upsertVisitSuccess, (state, action) =>
    adapter.upsertOne(action.visit, state)
  ),
  on(VisitActions.updateVisit, (state, action) =>
    adapter.updateOne(action.visit, state)
  ),
  on(VisitActions.deleteVisitSuccess, (state, action) =>
    adapter.removeOne(action.id, state)
  ),

  // Load client visits refactored from clients.actions.ts
  on(VisitActions.loadClientVisitsSuccess, (state, { visits }) =>
    adapter.setAll(visits, state)
  ),

  on(
    VisitActions.loadVisitsSuccess,
    (state, { page, pageSize, visits, totalItems }) => {
      const totalPages = Math.ceil(totalItems / pageSize);
      const hasNextPage = page < totalPages;
      const hasPreviousPage = page > 1;

      const updatedStateWithEntities = adapter.removeAll({
        ...state,
        entities: {},
        ids: []
      });

      const finalUpdatedState = adapter.addMany(
        visits,
        updatedStateWithEntities
      );

      const paginationInfo = {
        page,
        pageSize,
        totalItems,
        totalPages,
        hasNextPage,
        hasPreviousPage
      };

      return {
        ...finalUpdatedState,
        paginationInfo
      };
    }
  ),
  on(VisitActions.setActiveVisitSuccess, (state, { visit }) => {
    const res = {
      ...state,
      selectedVisitId: visit?.id,
      entities: {
        ...state.entities
      }
    };
    if (visit?.id) res.entities[visit.id] = visit;
    return res;
  }),

  on(VisitActions.setPageLimit, (state, { pageSize }) => ({
    ...state,
    paginationInfo: {
      ...state.paginationInfo,
      pageSize
    }
  })),

  on(VisitActions.loadVisitsFailure, (state, { error }) => ({
    ...state,
    error
  })),

  // Handle clearing and resetting visits
  on(VisitActions.clearVisits, (state) => adapter.removeAll(state)),
  on(VisitActions.resetVisitsForm, (state) => ({
    ...state,
    selectedVisitId: null
  })),
  on(VisitActions.setActiveVisit, (state, { selectedVisitId }) => ({
    ...state,
    selectedVisitId
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
