import { ProcedureCode } from '../../shared/models';

export const NIGHT_MODE_THEME = 'BLACK-THEME';

export type Language = 'en' | 'bg';

export type ProcedureLength = {
  [key in ProcedureCode]: number;
};

export interface SettingsState {
  language: string;
  theme: string;
  autoNightMode: boolean;
  nightTheme: string;
  stickyHeader: boolean;
  pageAnimations: boolean;
  pageAnimationsDisabled: boolean;
  elementsAnimations: boolean;
  hour: number;
  procedureLength: ProcedureLength;
}
