import { getSelectedClient } from '../clients/clients.selectors';
import { createSelector } from '@ngrx/store';
import { adapter, proceduresFeatureKey } from './procedure.reducer';
import { selectRouteParams } from '../../core/core.state';
import { ProceduresState } from '../../shared/procedures/procedures.model';
import {
  zones,
  iterationCount,
  regimes,
  reminderPeriods
} from '../../shared/procedures/procedure/procedure.nomenclatures';
import { Procedure } from '../../shared/models';

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectProcedures = (state): ProceduresState =>
  state[proceduresFeatureKey];
export const selectAllProcedures = createSelector(selectProcedures, selectAll);
export const selectProcedureEntities = createSelector(
  selectProcedures,
  selectEntities
);

export const getSelectedProcedure = createSelector(
  selectProcedureEntities,
  selectRouteParams,
  (entities, params) => params && entities[params.id]
);

export const selectedLastUpdatedClientProcedures = createSelector(
  selectProcedures,
  getSelectedClient,
  (state, client) => state.lastUpdate[client.id]
);

export const getActiveProcedure = createSelector(
  selectProcedures,
  selectProcedureEntities,
  (state: ProceduresState, entities) =>
    entities && entities[state.selectedProcedureId]
);

export const getIsNewProcedure = createSelector(
  selectProcedures,
  (state: ProceduresState) => state.newProcedure
);

export const getProcedureZones = createSelector(
  selectProcedures,
  (): Zone[] => zones as unknown as Zone[]
);

export const getProcedureIterationCnt = createSelector(
  selectProcedures,
  (): number[] => iterationCount
);

export const getProcedureRegime = createSelector(
  selectProcedures,
  (): Procedure['regime'][] => regimes
);

export const getProcedureReminderPeriods = createSelector(
  selectProcedures,
  (): Procedure['nextReminder'][] => reminderPeriods
);
