import { MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { debug } from './debug.reducer';
import { initStateFromLocalStorage } from './init-state-from-local-storage.reducer';
import { AppState } from '../core.state';

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

if (!environment.production) {
  if (!environment.dev) {
    metaReducers.unshift(debug);
  }
}
