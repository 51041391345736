import { RemindersState } from '../store/reminders/reminders.model';
import { VisitsState } from '../store/visits/visits.model';
import { ClientsState } from '../store/clients/clients.model';
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';

import { AuthState } from './auth/auth.models';
import { RouterStateUrl } from './router/router.state';
import { SettingsState } from './settings/settings.model';
import * as fromNotification from './notifications/store/notification.reducer';
import { ProceduresState } from '../shared/procedures/procedures.model';
import { LoadingState } from './loading/loading.models';

export const selectLoadingState = (state: AppState) => state.loading;
export const selectAuthState = (state: AppState) => state.auth;
export const selectSettingsState = (state: AppState) => state.settings;
export const selectRouterState = (state: AppState) => state.router;

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl // select the current url`
} = getRouterSelectors(selectRouterState);

export interface AppState {
  loading: LoadingState;
  auth: AuthState;
  settings: SettingsState;
  router: RouterReducerState<RouterStateUrl>;
  clients: ClientsState;
  visits: VisitsState;
  reminders: RemindersState;
  procedures: ProceduresState;
  [fromNotification.notificationFeatureKey]: fromNotification.State;
}
