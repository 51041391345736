import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  switchMap,
  map,
  catchError,
  tap,
  withLatestFrom,
  filter
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ProceduresService } from '../../core/procedures/procedures.service';
import {
  addProcedure,
  addProcedureSuccess,
  deleteProcedure,
  deleteProcedureSuccess,
  upsertProcedure,
  upsertProcedureSuccess,
  submitProcedure,
  setActiveProcedure,
  setActiveProcedureFailure,
  setActiveProcedureSuccess,
  setIsNewProcedure,
  addProcedureFail,
  loadClientProcedures,
  loadClientProceduresSuccess
} from './procedure.actions';
import { getActiveVisit } from '../visits/visit.selectors';
import { selectProcedureEntities } from './procedure.selectors';
import { ProcedureRecord } from '../../shared/models';
import { NotificationService } from '../../core/notifications/notification.service';

@Injectable()
export class ProcedureEffects {
  // Effect to add a new procedure
  public addProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProcedure),
      switchMap((action) => this.proceduresService.add(action.procedure)),
      map((procedure) =>
        upsertProcedureSuccess({
          procedure: { ...procedure, visitId: procedure.visit.id },
          visit: procedure.visit
        })
      ),
      catchError(() => {
        this.notificationService.error('Failed to add procedure.');
        return of({ type: 'ADD_PROCEDURE_FAILURE' });
      })
    )
  );

  // Effect to update an existing procedure
  public updateProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertProcedure),
      switchMap(({ procedure, visit }) =>
        this.proceduresService.update(procedure).pipe(
          map((procedure) =>
            upsertProcedureSuccess({
              procedure: { ...procedure, visitId: procedure.visit.id },
              visit
            })
          )
        )
      ),
      catchError(() => {
        this.notificationService.error('Failed to update procedure.');
        return of({ type: 'UPDATE_PROCEDURE_FAILURE' });
      })
    )
  );

  // Procedure success effect to handle notification and state update
  public upsertProcedureSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(upsertProcedureSuccess),
        tap(() =>
          this.store.dispatch(setActiveProcedure({ selectedProcedureId: null }))
        ),
        tap(() =>
          this.store.dispatch(setIsNewProcedure({ newProcedure: false }))
        ),
        tap(() => {
          this.notificationService.info('oniks.common.form.operation-success');
        }),
        catchError(() => {
          this.notificationService.error('Failed to update procedure.');
          return of({ type: 'UPDATE_PROCEDURE_FAILURE' });
        })
      ),
    { dispatch: false }
  );

  // Effect to delete a procedure
  public deleteProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProcedure),
      switchMap((action) =>
        this.proceduresService.delete(action.procedure.id).pipe(
          map(() => deleteProcedureSuccess()),
          catchError(() => {
            this.notificationService.error('Failed to delete procedure.');
            return of({ type: 'DELETE_PROCEDURE_FAILURE' });
          })
        )
      )
    )
  );

  // Effect to submit a new or existing procedure
  public submitProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitProcedure),
      withLatestFrom(
        this.store.pipe(
          select(getActiveVisit),
          filter((visit) => !!visit)
        )
      ),
      switchMap(([action, visit]) => {
        if (!visit) {
          return of(addProcedureFail({ error: 'Visit missing' }));
        }

        const procedureRecord: ProcedureRecord = {
          ...action.procedureRecord,
          visitId: visit.id
        };

        const isExistingProcedure = !!procedureRecord.id;

        return isExistingProcedure
          ? of(upsertProcedure({ procedure: procedureRecord, visit }))
          : of(addProcedure({ procedure: procedureRecord, visit }));
      }),
      catchError((error) => of(addProcedureFail({ error })))
    )
  );

  // Effect to notify when a procedure is successfully saved
  public procedureSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addProcedureSuccess),
        tap(() => {
          this.notificationService.info('Procedure saved successfully');
        })
      ),
    { dispatch: false }
  );

  // Effect to notify and handle procedure failures
  public procedureFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addProcedureFail),
        tap(({ error }) => {
          this.notificationService.error(`Failed to save procedure: ${error}`);
          this.store.dispatch(
            setActiveProcedure({ selectedProcedureId: null })
          );
          this.store.dispatch(setIsNewProcedure({ newProcedure: false }));
        })
      ),
    { dispatch: false }
  );

  // Effect to set the active procedure from store or backend
  public setActiveProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveProcedure),
      withLatestFrom(this.store.pipe(select(selectProcedureEntities))),
      switchMap(([action, procedures]) => {
        const procedureId = action.selectedProcedureId;
        const existingProcedure = procedures[procedureId] || null;

        if (existingProcedure || !procedureId) {
          return of(
            setActiveProcedureSuccess({ procedure: existingProcedure })
          );
        }

        return this.proceduresService.getProcedureById(procedureId).pipe(
          map((procedure) => setActiveProcedureSuccess({ procedure })),
          catchError((error) => {
            this.notificationService.error('Failed to load procedure');
            return of(setActiveProcedureFailure({ error }));
          })
        );
      })
    )
  );

  // Effect to load client procedures (moved from clients.effects.ts)
  public loadClientProcedures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadClientProcedures),
      switchMap((action) =>
        this.proceduresService.getClientProcedures(action.clientId).pipe(
          map((procedures) =>
            loadClientProceduresSuccess({
              procedures,
              clientId: action.clientId
            })
          ),
          catchError(() => {
            this.notificationService.error('Failed to load procedures.');
            return of({ type: 'LOAD_PROCEDURES_FAILURE' });
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly proceduresService: ProceduresService,
    private readonly notificationService: NotificationService,
    private readonly store: Store
  ) {}
}
