import { RemindersState } from './reminders.model';
import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ReminderActions from './reminder.actions';
import { GetReminderDto } from '../../shared/models';

export const remindersFeatureKey = 'reminders';

export const adapter: EntityAdapter<GetReminderDto> =
  createEntityAdapter<GetReminderDto>();

export const initialState: RemindersState = adapter.getInitialState({
  selectedReminderId: null,
  ids: []
});

export const reminderReducer = createReducer(
  initialState,
  on(ReminderActions.updateReminder, (state, action) =>
    adapter.updateOne(action.reminder, state)
  ),
  on(ReminderActions.updateReminders, (state, action) =>
    adapter.updateMany(action.reminders, state)
  ),
  on(ReminderActions.deleteReminder, (state, action) =>
    adapter.removeOne(action.reminder.id, state)
  ),
  on(ReminderActions.deleteReminders, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ReminderActions.loadRemindersSuccess, (state, action) =>
    adapter.setAll(action.reminders, state)
  ),
  on(ReminderActions.clearReminders, (state) => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
