import { Procedure, Zone } from '../../models';

const getConsecutive = (max: number, step: number, min = 0): number[] => {
  const arr = [];
  let i = min;
  while (i < max) {
    i = i + step;
    arr.push(i);
  }
  return arr;
};

export const zones: Zone[] = ['body', 'face', 'legs'];

export const regimes: Procedure['regime'][] = ['dynamic', 'static'];
export const staticIntensities: Procedure['intensity'][] = (() =>
  getConsecutive(38, 1))();
export const dynamicIntensities: Procedure['intensity'][] = (() =>
  getConsecutive(15, 1))();
export const reminderPeriods: Procedure['nextReminder'][] = [
  0,
  ...getConsecutive(90, 5, 20)
];
export const staticFrequencies: Procedure['frequency'][] = [1, 2, 3];
export const dynamicFrequencies: Procedure['frequency'][] = [5, 10, 15];

export const iterationCount = (() => getConsecutive(50, 1))();

export const procedureItems: Omit<Procedure, 'visitId'>[] = [
  {
    code: 'top-lips',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'face',
    nextReminder: 0
  },
  {
    code: 'whole-face',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'face',
    nextReminder: 0
  },
  {
    code: 'cheekbones',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'face',
    nextReminder: 0
  },
  {
    code: 'beard',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'face',
    nextReminder: 0
  },
  {
    code: 'sideburns',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'face',
    nextReminder: 0
  },
  {
    code: 'throat',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'face',
    nextReminder: 0
  },
  {
    code: 'neck',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'face',
    nextReminder: 0
  },
  {
    code: 'armpits',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'stomach-line',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'bikini',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'areols',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'shoulders',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'half-hands',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'stomach',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'waist',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'breasts',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'backside',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'intimate',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'hands',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'back',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'body',
    nextReminder: 0
  },
  {
    code: 'half-legs',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'legs',
    nextReminder: 0
  },
  {
    code: 'legs',
    frequency: undefined,
    intensity: undefined,
    iterationCount: 1,
    zone: 'legs',
    nextReminder: 0
  }
];
