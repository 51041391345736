import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ProcedureActions from './procedure.actions';
import * as VisitActions from '../visits/visit.actions';
import { ProceduresState } from '../../shared/procedures/procedures.model';
import { ProcedureRecord } from '../../shared/models';

export const proceduresFeatureKey = 'procedures';

export const adapter: EntityAdapter<ProcedureRecord> =
  createEntityAdapter<ProcedureRecord>();

export const initialState: ProceduresState = adapter.getInitialState({
  newProcedure: false,
  selectedProcedureId: null,
  lastUpdate: null,
  ids: []
});

export const proceduresReducer = createReducer(
  initialState,
  on(ProcedureActions.addProcedureSuccess, (state, { procedure, visitId }) => ({
    ...state,
    entities: {
      ...state.entities,
      [procedure.id]: procedure
    },
    visitId
  })),
  on(ProcedureActions.upsertProcedureSuccess, (state, action) =>
    adapter.upsertOne(action.procedure, state)
  ),
  on(ProcedureActions.updateProcedure, (state, action) =>
    adapter.updateOne(action.procedure, state)
  ),
  on(ProcedureActions.updateProcedures, (state, action) =>
    adapter.updateMany(action.procedures, state)
  ),
  on(ProcedureActions.deleteProcedure, (state, action) =>
    adapter.removeOne(action.procedure.id, state)
  ),
  on(ProcedureActions.deleteProcedures, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ProcedureActions.clearProcedures, (state) => adapter.removeAll(state)),
  on(ProcedureActions.setActiveProcedure, (state, { selectedProcedureId }) => ({
    ...state,
    selectedProcedureId
  })),
  on(ProcedureActions.setActiveProcedureSuccess, (state, { procedure }) => {
    const procedureId = procedure?.id;
    // If procedureId exists, update the state using adapter.upsertOne
    if (procedureId) {
      return adapter.upsertOne(procedure, {
        ...state,
        selectedProcedureId: procedureId
      });
    }

    return state;
  }),
  // Handle success of setting an active visit and updating related procedures
  on(VisitActions.setActiveVisitSuccess, (state, { visit }) => {
    const procedures =
      visit?.procedures?.map((procedure) => ({
        ...procedure,
        visitId: visit.id // Ensure that each procedure has a visitId field if needed
      })) || [];

    return procedures ? adapter.setAll(procedures, state) : state;
  }),
  on(ProcedureActions.setIsNewProcedure, (state, { newProcedure }) => ({
    ...state,
    newProcedure
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
