import { createSelector } from '@ngrx/store';
import {
  Procedure,
  ProcedureRecord,
  VisitProcedureRecord
} from '../../shared/models';
import {
  getActiveProcedure,
  selectAllProcedures,
  selectProcedures
} from '../procedures/procedure.selectors';
import { getActiveVisit } from '../visits/visit.selectors';
import { procedureItems } from '../../shared/procedures/procedure/procedure.nomenclatures';
import { ProceduresState } from '../../shared/procedures/procedures.model';
import { Selector } from '@ngrx/store';
import { AppState } from '../../core/core.state';

export const getVisitProcedures = createSelector(
  getActiveVisit,
  selectAllProcedures,
  (activeVisit, procedures: ProcedureRecord[]): VisitProcedureRecord[] => {
    if (!activeVisit || !activeVisit?.procedures) {
      return [];
    }

    const visitProcedures =
      procedures.filter((procedure) => procedure.visitId === activeVisit?.id) ||
      [];

    // Map the visit details with the corresponding procedures
    return visitProcedures?.length > 0
      ? visitProcedures.map((procedure) => ({
          ...activeVisit,
          ...procedure,
          visitId: activeVisit.id
        }))
      : [];
  }
);

export const getProcedureItems = createSelector(
  selectProcedures,
  getVisitProcedures,
  getActiveProcedure as Selector<AppState, Procedure>,
  (
    state: ProceduresState,
    visitProcedures: ProcedureRecord[],
    activeProcedure: Procedure
  ): Omit<Procedure, 'visitId'>[] =>
    procedureItems.filter(
      (item) =>
        !visitProcedures?.find(
          (el) => el.code === item.code && el.code !== activeProcedure?.code
        )
    )
);
