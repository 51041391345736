import { Store, select } from '@ngrx/store';
import { selectIsAuthenticated } from './auth.selectors';
import { AppState } from '../core.state';
import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { tap } from 'rxjs';

export const authGuard: CanActivateFn = () => {
  const store: Store<AppState> = inject(Store);
  const router = inject(Router);
  return store.pipe(
    select(selectIsAuthenticated),
    tap((isAuth) => {
      if (!isAuth) router.navigate(['login']);
      console.log({ isAuth });
    })
  );
};
