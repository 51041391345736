import {
  getSelectedClient,
  selectAllClients
} from '../clients/clients.selectors';
import { adapter, visitsFeatureKey } from './visit.reducer';
import { VisitsState } from '../../store/visits/visits.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState, selectRouteParams } from '../../core/core.state';
import {
  Visit,
  VisitListItem,
  PaginationInfo,
  Client
} from '../../shared/models';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectVisitsState = createFeatureSelector<AppState, VisitsState>(
  visitsFeatureKey
);
export const selectAllVisits = createSelector(selectVisitsState, selectAll);
export const selectVisitEntities = createSelector(
  selectVisitsState,
  selectEntities
);

export const getRouterParamVisit = createSelector(
  selectVisitEntities,
  selectRouteParams,
  (entities, params) => params && entities[params.id]
);

export const selectVisitsPaginationInfo = createSelector(
  selectVisitsState,
  (state: VisitsState) => ({
    page: state.paginationInfo.page,
    pageSize: state.paginationInfo.pageSize,
    totalItems: state.paginationInfo.totalItems,
    totalPages: state.paginationInfo.totalPages,
    hasNextPage: state.paginationInfo.hasNextPage,
    hasPreviousPage: state.paginationInfo.hasPreviousPage
  })
);

export const getActiveVisit = createSelector(
  selectVisitEntities,
  selectVisitsState,
  selectAllClients,
  (entities, state: VisitsState, clients: Client[]): Visit | null => {
    const selectedVisitId = state.selectedVisitId;
    return selectedVisitId
      ? {
          ...entities[selectedVisitId],
          client: clients.find(
            (client) => client.id === entities[selectedVisitId]?.clientId
          )
        }
      : null;
  }
);

export const selectPaginationInfo = createSelector(
  selectVisitsState,
  (state: VisitsState) => state.paginationInfo
);

export const selectPaginatedVisits = createSelector(
  selectVisitEntities,
  selectPaginationInfo,
  (
    entities,
    paginationInfo
  ): { visits: VisitListItem[]; paginationInfo: PaginationInfo } => {
    return {
      visits: Object.values(entities)
        .sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime(); // For descending order
        })
        .map((visit) => {
          const formattedMinutes =
            visit.minutes == 0
              ? '00'
              : visit.minutes.toString().padStart(2, '0'); // Format minutes
          return {
            ...visit,
            clientName: visit.client?.name || '',
            formattedMinutes // Add formatted minutes to the return object
          } as VisitListItem;
        }),
      paginationInfo
    };
  }
);

export const selectClientVisits = createSelector(
  selectAllVisits,
  getSelectedClient,
  (visits: Visit[], client: Client) =>
    visits
      .filter((visit) => client && visit.clientId === client.id)
      .map((visit) => {
        const date = new Date(visit.date);
        date.setHours(visit.hour);
        date.setMinutes(visit.minutes);
        const formattedMinutes =
          visit.minutes == 0 ? '00' : visit.minutes.toString().padStart(2, '0');
        return {
          ...visit,
          id: visit.id,
          clientName: client?.name || '',
          date,
          price: 2,
          procedures: visit.procedures,
          formattedHour: formattedMinutes
        } as VisitListItem;
      })
);
