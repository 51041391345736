import browser from 'browser-detect';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import packageInfo from '../../../../../package.json';
import { environment as env } from '../../environments/environment';

import {
  authLogout,
  routeAnimations,
  LocalStorageService,
  selectIsAuthenticated,
  selectSettingsStickyHeader,
  selectSettingsLanguage,
  selectEffectiveTheme
} from '../core/core.module';
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage
} from '../core/settings/settings.actions';

import { NgxSpinnerService } from 'ngx-spinner';
import { selectIsLoading } from '../core/loading/loading.selectors';

@Component({
  selector: 'oniks-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  readonly showLanguageMenu = false;

  isProd = env.production;
  version = packageInfo.version;
  year = new Date().getFullYear();
  logo = '../../assets/logo.png';
  languages = ['en', 'bg'];
  navigation = [
    { link: 'reminders', label: 'oniks.menu.reminders' },
    { link: 'visits', label: 'oniks.menu.visits' },
    { link: 'clients', label: 'oniks.menu.clients' }
  ];
  navigationSideMenu = [
    ...this.navigation,
    { link: 'settings', label: 'oniks.menu.settings' }
  ];

  isAuthenticated$: Observable<boolean>;
  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: Observable<string>;

  constructor(
    private store: Store,
    private storageService: LocalStorageService,
    private spinner: NgxSpinnerService
  ) {
    // Delete ANMS-ORDERS and ANMS-CLIENTS from localStorage
    localStorage.removeItem('ANMS-ORDERS');
    localStorage.removeItem('ANMS-CLIENTS');
    console.log(
      'ANMS-ORDERS and ANMS-CLIENTS have been removed from localStorage.'
    );
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    this.storageService.testLocalStorage();
    // this.unRegisterSW();

    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));

    this.store.pipe(select(selectIsLoading)).subscribe((loading) => {
      if (loading) {
        console.log('Loading!', loading);
        this.spinner.show();
      } else {
        console.log('Stop Loading!', loading);
        this.spinner.hide();
      }
    });
  }

  onLogoutClick() {
    this.store.dispatch(authLogout());
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }));
  }

  private unRegisterSW(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister().then((success) => {
            if (success) {
              console.log('Service Worker unregistered successfully');
            }
          });
        }
      });
    }
  }
}
