import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ClientsState, ExtendedClient } from './clients.model';
import * as ClientsActions from './clients.actions';
import * as ProcedureActions from '../procedures/procedure.actions';

export const clientsFeatureKey = 'clients';

export type State = EntityState<ExtendedClient>;

export const adapter: EntityAdapter<ExtendedClient> =
  createEntityAdapter<ExtendedClient>();

export const initialState: ClientsState = adapter.getInitialState({
  selectedClientId: null,
  ids: []
});

export const clientReducer = createReducer(
  initialState,
  on(ClientsActions.addClientSuccess, (state, action) =>
    adapter.addOne(action.client, state)
  ),
  on(ClientsActions.upsertClient, (state, action) =>
    adapter.upsertOne(action.client, state)
  ),
  on(ClientsActions.addClients, (state, action) =>
    adapter.addMany(action.clients, state)
  ),
  on(ClientsActions.upsertClients, (state, action) =>
    adapter.upsertMany(action.clients, state)
  ),
  on(ClientsActions.updateClient, (state, action) =>
    adapter.updateOne(action.client, state)
  ),
  on(ClientsActions.updateClients, (state, action) =>
    adapter.updateMany(action.clients, state)
  ),
  on(ClientsActions.deleteClientSuccess, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ClientsActions.deleteClients, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ClientsActions.loadClients, (state, action) =>
    adapter.setAll(action.clients, state)
  ),
  on(ClientsActions.clearClients, (state) => adapter.removeAll(state)),
  on(ClientsActions.resetClientForm, (state) => ({
    ...state,
    selectedClientId: null
  })),
  on(ClientsActions.setActiveClient, (state, { selectedClientId }) => ({
    ...state,
    selectedClientId
  })),
  on(
    ProcedureActions.loadClientProceduresSuccess,
    (state, { clientId, procedures }) => {
      const client = state.entities[clientId];
      if (!client) return state;

      return adapter.updateOne(
        {
          id: clientId,
          changes: {
            procedures
          }
        },
        state
      );
    }
  )
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
