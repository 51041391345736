import { createReducer, on } from '@ngrx/store';
import * as NotificationActions from './notifications.actions';

export const notificationFeatureKey = 'notification';

export interface State {
  isLoading: boolean;
}

export const initialState: State = {
  isLoading: false
};

export const reducer = createReducer(
  initialState,
  on(NotificationActions.startWait, (state) => ({ ...state, isLoading: true })),
  on(NotificationActions.endWait, (state) => ({ ...state, isLoading: false }))
);
