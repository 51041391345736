import { Environment } from './environment.model';
import packageJson from '../../../../package.json'; // Use ES Module import

export const environment: Environment = {
  appName: 'ONIKS',
  apiGw: {
    stageName: 'dev',
    version: 'v1',
    dynamoBaseUrl: 'https://2uonpw4dm7.execute-api.eu-central-1.amazonaws.com',
    nestBaseUrl: 'https://nd2dq9l36h.execute-api.eu-central-1.amazonaws.com'
  },
  applicationBaseUrl: 'https://oniks.click',
  apiKey: 'ase0RZsCbp11dpFP0EpD64Gxtw22JTlj1peH84dr',
  production: false,
  dev: true,
  i18nPrefix: '',
  versions: {
    app: packageJson.version
  },
  endpoints: {
    clientProcedures: 'client-procedures',
    calendar: 'calendar',
    clients: 'clients',
    reminders: 'reminders',
    visits: 'visits'
  }
};
