import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Visit } from '../../shared/models';

export const initVisits = createAction('[Visit/API] Init');

export const loadVisits = createAction(
  '[Visit/API] Load Visits',
  props<{
    page: number;
    pageSize: number;
    clientName: string;
    orderBy: string;
    orderDirection: string;
  }>()
);

export const loadVisitsFailure = createAction(
  '[Visit/API] Load Visits Failure',
  props<{ error: unknown }>()
);

export const loadVisitsSuccess = createAction(
  '[Visit/API] Load Visits Success',
  props<{
    page: number;
    pageSize: number;
    visits: Visit[];
    totalItems: number;
  }>()
);

export const setPageLimit = createAction(
  '[Visit/UI] Set Page Limit',
  props<{ pageSize: number }>()
);

export const addVisit = createAction(
  '[Visit/API] Add Visit',
  props<{ visit: Visit }>()
);

export const addVisitSuccess = createAction(
  '[Visit/API] Add Visit success',
  props<{ visit: Visit }>()
);

export const addVisitFail = createAction('[Visit/API] Add Visit fail');

export const addEvent = createAction(
  '[Visit/API] Add Event',
  props<{ visit: Visit }>()
);

export const updateEvent = createAction(
  '[Visit/API] Update Event',
  props<{ visit: Visit }>()
);

export const upsertVisit = createAction(
  '[Visit/API] Upsert Visit',
  props<{ visit: Visit }>()
);

export const upsertVisitSuccess = createAction(
  '[Visit/API] Upsert Visit Success',
  props<{ visit: Visit }>()
);

export const addVisits = createAction(
  '[Visit/API] Add Visits',
  props<{ visits: Visit[] }>()
);

export const upsertVisits = createAction(
  '[Visit/API] Upsert Visits',
  props<{ visits: Visit[] }>()
);

export const updateVisit = createAction(
  '[Visit/API] Update Visit',
  props<{ visit: Update<Visit> }>()
);

export const updateVisits = createAction(
  '[Visit/API] Update Visits',
  props<{ visits: Update<Visit>[] }>()
);

export const deleteVisit = createAction(
  '[Visit/API] Delete Visit',
  props<{ visit: Visit }>()
);

export const deleteVisitSuccess = createAction(
  '[Visit/API] Delete Visit success',
  props<{ id: number }>()
);

export const deleteVisits = createAction(
  '[Visit/API] Delete Visits',
  props<{ ids: string[] }>()
);

export const clearVisits = createAction('[Visit/API] Clear Visits');
export const resetVisitsForm = createAction('[Visit/API] Reset Visit Form');

export const setActiveVisit = createAction(
  '[Visit/API] Set active',
  props<{
    selectedVisitId: Visit['id'];
    dispatch?: boolean;
    refresh?: boolean;
  }>()
);

export const setActiveVisitSuccess = createAction(
  '[Visit] Set Active Visit Success',
  props<{ visit: Visit }>()
);

export const setActiveVisitFailure = createAction(
  '[Visit] Set Active Visit Failure',
  props<{ error: unknown }>()
);

// Newly added from clients.actions.ts (visit-related actions)
export const loadClientVisits = createAction(
  '[Visit] Load Client Visits',
  props<{ clientId: number }>()
);

export const loadClientVisitsSuccess = createAction(
  '[Visit] Load Client Visits Success',
  props<{ visits: Visit[] }>()
);

export const loadClientVisitsFailure = createAction(
  '[Visit] Load Client Visits Failure',
  props<{ error: unknown }>()
);

// Define the action
export const newVisitForClient = createAction(
  '[Visit] New Visit For Client',
  props<{ clientId: string }>()
);
