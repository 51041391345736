// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

:host ::ng-deep th.text-end > div {
  text-align: center;
  justify-content: flex-end;
}

:host ::ng-deep th.text-center > div {
  text-align: center;
  justify-content: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
