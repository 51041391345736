import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { clientReducer } from '../store/clients/clients.reducer';
import { reminderReducer } from '../store/reminders/reminder.reducer';
import { visitReducer } from '../store/visits/visit.reducer';
import { authReducer } from './auth/auth.reducer';
import { loadingReducer } from './loading/loading.reducer';
import { proceduresReducer } from '../store/procedures/procedure.reducer';
import { settingsReducer } from './settings/settings.reducer';
import * as fromNotification from './notifications/store/notification.reducer';
import { AppState } from './core.state';

export const reducers: ActionReducerMap<AppState> = {
  loading: loadingReducer,
  auth: authReducer,
  settings: settingsReducer,
  router: routerReducer,
  clients: clientReducer,
  visits: visitReducer,
  procedures: proceduresReducer,
  reminders: reminderReducer,
  [fromNotification.notificationFeatureKey]: fromNotification.reducer
};
