import { EntityState } from '@ngrx/entity';
import { Client, ProcedureVisitRecord, Visit } from '../../shared/models';

export enum ClientsRoutes {
  'form' = '/clients/client',
  'list' = '/clients/client-list'
}

export interface ExtendedClient extends Client {
  visits?: Visit[];
  procedures?: ProcedureVisitRecord[];
}

export interface ClientsState extends EntityState<ExtendedClient> {
  selectedClientId: Client['id'];
}

export interface State {
  clients: ClientsState;
}
