import { EntityState } from '@ngrx/entity';
import { PaginationInfo, Visit } from '../../shared/models';

export enum VisitRoutes {
  'form' = '/visits/visit',
  'visitForClient' = '/visits/client',
  'list' = '/visits/visit-list'
}

export interface VisitsState extends EntityState<Visit> {
  selectedVisitId: Visit['id'];
  paginationInfo: PaginationInfo;
}

export interface State {
  visits: VisitsState;
}
