import { createSelector } from '@ngrx/store';
import { selectLoadingState } from '../core.state';
import { LoadingState } from './loading.models';

export const selectLoading = createSelector(
  selectLoadingState,
  (state: LoadingState) => state
);

export const selectIsLoading = createSelector(
  selectLoadingState,
  (state: LoadingState) => state.httpOperations > 0
);
