import {
  dynamicFrequencies,
  dynamicIntensities,
  staticFrequencies,
  staticIntensities
} from './procedure.nomenclatures';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable, of, Subject, zip } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  getActiveProcedure,
  getProcedureIterationCnt,
  getProcedureRegime,
  getProcedureReminderPeriods,
  getProcedureZones
} from '../../../store/procedures/procedure.selectors';
import {
  setActiveProcedure,
  setIsNewProcedure,
  submitProcedure
} from '../../../store/procedures/procedure.actions';
import { Procedure, ProcedureRecord } from '../../models';
import { ROUTE_ANIMATIONS_ELEMENTS } from '../../../core/animations/route.animations';
import { AppState } from '../../../core/core.state';
import { getClientProcedures } from '../../../store/clients/clients.selectors';
import {
  getProcedureItems,
  getVisitProcedures
} from '../../../store/shared/shared.selectors';

@Component({
  selector: 'oniks-procedure',
  templateUrl: './procedure.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcedureComponent implements OnInit, OnDestroy {
  procedures$: Observable<Omit<Procedure, 'visitId'>[]>;

  iterationCounts$: Observable<number[]>;

  zones$;

  regimes$: Observable<Procedure['regime'][]>;

  nextReminders$: Observable<Procedure['nextReminder'][]>;

  activeProcedure$: Observable<ProcedureRecord>;

  intensities: number[];

  frequencies: number[];

  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  form: UntypedFormGroup = this.fb.group({
    id: [''],
    zone: ['', [Validators.required]],
    code: ['', [Validators.required]],
    iterationCount: ['', [Validators.required]],
    price: ['', [Validators.pattern('^[0-9]*$')]],
    nextReminder: [30, [Validators.required]],
    regime: ['dynamic', [Validators.required]],
    intensity: [''],
    frequency: [''],
    comment: ['']
  });

  private unsubscribe$ = new Subject<void>();

  constructor(
    public store: Store<AppState>,
    public fb: UntypedFormBuilder
  ) {
    this.zones$ = this.store.pipe(select(getProcedureZones));
    this.regimes$ = this.store.pipe(select(getProcedureRegime));
    this.nextReminders$ = this.store.pipe(select(getProcedureReminderPeriods));
    this.activeProcedure$ = this.store.pipe(select(getActiveProcedure));
  }

  ngOnInit(): void {
    // this.toggleDisabled(false);
    this.watchRegime();
    this.watchZones();
    this.watchCode();
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(getActiveProcedure),
        filter((c) => !!c),
        distinctUntilChanged((a, b) => a?.id === b?.id),
        tap((form) => this.form.patchValue(form))
      )
      .subscribe();

    this.store
      .pipe(
        select(getVisitProcedures),
        filter((el) => !!el),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((a, b) => a[0]?.id === b[0]?.id)
      )
      .subscribe();
  }

  submit(): false {
    if (this.form.valid) {
      const procedureRecord: ProcedureRecord = this.form.value;

      this.store.dispatch(
        submitProcedure({
          procedureRecord
        })
      );

      this.form.reset();
    }
    return false;
  }

  cancel(): false {
    this.store.dispatch(setIsNewProcedure({ newProcedure: false }));
    this.store.dispatch(setActiveProcedure({ selectedProcedureId: null }));
    return false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private watchZones(): void {
    this.procedures$ = this.form.controls['zone'].valueChanges.pipe(
      startWith('all'),
      withLatestFrom(this.store.pipe(select(getProcedureItems))),
      // tap(([zone]) => this.toggleDisabled(zone !== 'all')),
      map(([zone, items]) =>
        zone === 'all' ? items : items.filter((item) => item.zone === zone)
      )
    );
  }

  private watchRegime(): void {
    this.form
      .get('regime')
      .valueChanges.pipe(
        startWith('dynamic'),
        takeUntil(this.unsubscribe$),
        tap((regime) => {
          if (regime === 'static') {
            this.intensities = staticIntensities;
            this.frequencies = staticFrequencies;
          } else {
            this.intensities = dynamicIntensities;
            this.frequencies = dynamicFrequencies;
          }
        })
      )
      .subscribe();
  }

  private watchCode(): void {
    this.iterationCounts$ = this.form.get('code').valueChanges.pipe(
      startWith(this.form.get('code').value),
      switchMap((code) =>
        zip(
          of(code),
          combineLatest([
            this.store.pipe(select(getProcedureIterationCnt)),
            this.store.pipe(select(getClientProcedures)),
            this.store.pipe(select(getActiveProcedure))
          ])
        )
      ),
      map(([code, [iterationCountAll, clientProcedures, activeProcedure]]) =>
        iterationCountAll.filter(
          (iterationCount) =>
            !clientProcedures.find((el) => {
              const notAnActiveProcedure = el.code !== activeProcedure?.code; // TODO - some blind changes made during the backend migration
              return (
                notAnActiveProcedure &&
                el.iterationCount === iterationCount &&
                el.code === (code || activeProcedure?.code)
              );
            })
        )
      )
    );
  }
}
