import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor() {}

  public get nestApi(): string {
    const isLocal = environment.apiGw.stageName === 'local';
    return isLocal
      ? 'http://localhost:3000'
      : `${environment.apiGw.nestBaseUrl}/${environment.apiGw.version}/${environment.apiGw.stageName}`;
  }

  public get apiGwUrl(): string {
    return `${environment.apiGw.dynamoBaseUrl}/${environment.apiGw.stageName}/${environment.apiGw.version}`;
  }

  public get headers(): HttpHeaders {
    return new HttpHeaders({ 'X-API-KEY': environment.apiKey });
  }
}
